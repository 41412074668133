import React from "react";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import { HeaderStyled } from "./styled";
import { inject, observer } from "mobx-react";
import {
  Menu,
  Dropdown,
  Avatar,
  Tag,
  Modal,
  Popover,
  Button,
  Select,
} from "antd";
import AuthStore from "../../stores/auth/auth.store";
import Icon from "@ant-design/icons";
import { t } from "i18next";
import { TfiMapAlt } from "react-icons/tfi";
import { ReactSVG } from "react-svg";

import betonIcon from "../../assets/beton.svg";
import homeIcon from "../../assets/home.svg";
import mixer from "../../assets/concrete-mixer.svg";

import reportIcon from "../../assets/report.svg";
import menu_arrowIcon from "../../assets/menu_arrow.svg";
import { observable } from "mobx";
import axios from "axios";
import { numberToFactorySingle } from "../../helpers/numberToFactory";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class Header extends React.PureComponent<Props, any> {
  @observable factories: any = [];
  @observable menu_select_factorry: any;

  state = {
    visible: false,
    placement: "left",
    userName: "",
    factory: "",
    logo: "",
    popVisible: false,
  };

  componentDidMount = async () => {
    const group_id = this.props!.authStore!.getUserGroipID();
    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/factories/getByCompany/${group_id}`
    );

    this.factories = rsp.data;

    this.menu_select_factorry = (
      <Menu>
        {this.factories?.map((factory: any, index: number) => (
          <Menu.Item
            key={index}
            onClick={() => this.changeUserFactory(factory.id)}
          >
            <div>
              {t("factory")} {factory?.factory_name}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );

    console.log({ t: this.factories });

    this.setState({
      userName: this.props!.authStore!.getUserName(),
      factory: this.props!.authStore!.getUserFactoryName(),
      logo: this.props!.authStore!.getUserLogo(),
    });
  };

  changeUserFactory = (id: number) => {
    let user = JSON.parse(localStorage.getItem("b_user"));

    if (user) {
      user.factory_id = id;
      user.factory_name = numberToFactorySingle(String(id));
    }

    localStorage.setItem("b_user", JSON.stringify(user));
    window.location.reload();
  };

  getPath = () => {
    return this.props.location.pathname;
  };

  menuAuth = (
    <Menu>
      <Menu.Item
        key="logout"
        onClick={() => {
          localStorage.removeItem("b_user");
          window.location.href = `/login`;
        }}
      >
        {t("logOut")}
      </Menu.Item>
    </Menu>
  );

  menu = (
    <Menu>
      <div className="menu">
        <div className="menu-items">
          <div className="desktop">
            <div className="flex menu-title">
              <label htmlFor="">{t("realTimeManagement")}</label>
            </div>
            <div className="sub-menu">
              <Link
                className={`flex ${
                  this.getPath() === "/addons" ? "current-path" : ""
                }`}
                to="/addons"
              >
                {t("factoryControl")}
              </Link>

              <Link
                className={`flex ${
                  this.getPath() === "/addons" ? "current-path" : ""
                }`}
                to="/addons"
              >
                {t("factoryControl")}
              </Link>

              <Link
                className={`flex ${
                  this.getPath() === "/concrete/view" ? "current-path" : ""
                }`}
                to="/concrete/view"
              >
                {t("mapDisplay")}
              </Link>

              <Link
                className={`flex ${
                  this.getPath() === "/concrete/slump" ? "current-path" : ""
                }`}
                to="/concrete/slump"
              >
                {t("certifiedControl")}
              </Link>
            </div>

            <div className="flex menu-title">
              <label htmlFor=""> {t("deliveryHistory")}</label>
            </div>

            <div className="sub-menu">
              <Link
                className={`flex ${
                  this.getPath() === "/concrete/delivery" ? "current-path" : ""
                }`}
                to="/concrete/delivery"
              >
                {t("history")}
              </Link>
            </div>
            <div className="flex menu-title">
              <label htmlFor="">{t("managementOf")}</label>
            </div>
            <div className="sub-menu">
              <Link
                className={`flex ${
                  this.getPath() === "/concrete/unusual" ? "current-path" : ""
                }`}
                to="/concrete/unusual"
              >
                {t("exceptions")}
              </Link>

              <Link
                className={`flex ${
                  this.getPath() === "/concrete/unusual" ? "current-path" : ""
                }`}
                to="/concrete/unusual"
              >
                {t("trackMixer")}
              </Link>
            </div>

            <div className="flex menu-title">
              <label htmlFor=""> {t("reports")}</label>
            </div>

            <div className="sub-menu">
              <Link
                className={`flex ${
                  this.getPath() === "/reports" ? "current-path" : ""
                }`}
                to="/reports"
              >
                <div> {t("reports")}</div>
              </Link>
            </div>

            <div className="flex menu-title">
              <label htmlFor=""> Conext</label>
            </div>
            <div className="sub-menu">
              <Link
                className={`flex ${
                  this.getPath() === "/sensors/status" ? "current-path" : ""
                }`}
                to="/sensors/status"
              >
                {t("sensorsStatus")}{" "}
              </Link>
            </div>
          </div>

          <div className="">
            {/* <Menu.Item key="/settings">
              <Link to="/settings">{t("settings")}</Link>
            </Menu.Item> */}
            <Menu.Item
              key="logout"
              onClick={() => {
                localStorage.removeItem("b_user");
                window.location.href = `/login`;
              }}
            >
              {t("logOut")}
            </Menu.Item>
          </div>
        </div>
      </div>
    </Menu>
  );

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <HeaderStyled className="header flex flexCol">
        <Popover
          placement="rightBottom"
          title={t("chooseFactory")}
          content={this.factories?.map((factory: any, index: number) => (
            <div
              className="pointer"
              onClick={() => this.changeUserFactory(factory.id)}
            >
              {factory.factory_name}
            </div>
          ))}
          trigger="click"
        >
          <Tag
            color="magenta"
            className="factory-name flex align-center justify-between"
            dir="ltr"
          >
            {JSON.parse(localStorage.getItem("b_user")).factory_name
              ? JSON.parse(localStorage.getItem("b_user")).factory_name
              : this.state.factory}
            {/* <img src={this.state.logo} width={60} /> */}
          </Tag>
        </Popover>
        <Popover
          placement="rightBottom"
          title={t("menu")}
          content={this.menuAuth}
        >
          <Tag color="magenta" className="factory-name">
            {this.state.userName}
          </Tag>
        </Popover>
      </HeaderStyled>
    );
  }
}
export default withRouter(Header);
