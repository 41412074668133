import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import parseObjectToUrlParams from "../../../utils/parseObjectToUrlParams";
import TopFilter from "../common/TopFilter/TopFilter";
import TrackHistory, {
  CustomTable,
} from "../Dashboard/TrackHistory/TrackHistory";
import { ReportsStyled } from "./styled";
import { t } from "i18next";
import AuthStore from "../../../stores/auth/auth.store";
import axios from "axios";
import { ColumnType } from "antd/lib/table";
import { Modal, Slider } from "antd";
import { useTranslation } from "react-i18next";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

async function fetchOpenReport(
  group_id: number | string,
  filters: Record<string, any>
): Promise<MixtureBehavior[]> {
  const rsp = await axios.get(
    `${process.env.REACT_APP_API_URL}/mixtures_behavior/open-report/${group_id}?` +
      parseObjectToUrlParams(filters)
  );

  return rsp.data.data;
}

async function updateReport(
  report_id: number,
  values: { report_status?: string; action_status?: string }
) {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/mixtures_behavior/update-report/${report_id}`,
      values
    );
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}
async function updateReports(values: {
  report_status?: string;
  action_status?: string;
  ids: number[];
}) {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/mixtures_behavior/update-reports`,
      values
    );
    return true;
  } catch (error) {
    console.error(error);
  }

  return false;
}

type MixtureBehavior = {
  createdAt: string;
  date: string;
  factory_id: number;
  group_id: number;
  id: number;
  mixture_code: string;
  mixture_name: string;
  number_of_deliveries_day: number;
  number_of_deliveries_month: number;
  number_of_deliveries_week: number;
  slump_deviation_day: number;
  slump_deviation_month: number;
  slump_deviation_week: number;
  total_cube_day: number;
  total_cube_month: number;
  total_cube_week: number;
  total_cube_month_company: number;
  updatedAt: string;
  status: string;
  report_status?: string;
  action_status?: string;
};

function getSavingPotential(item: MixtureBehavior, displayTime: string) {
  if (displayTime === "day") {
    if (item.slump_deviation_day < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_day / 25) * item.total_cube_day * 0.5
    );
  }

  if (displayTime === "week") {
    if (item.slump_deviation_week < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_week / 25) * item.total_cube_month_company * 0.5
    );
  }

  if (displayTime === "month") {
    if (item.slump_deviation_month < 0) {
      return 0;
    }
    return Math.round(
      (item.slump_deviation_month / 25) * item.total_cube_month * 0.5
    );
  }
}

const columns = [
  { title: t("Mixture name and code"), dataIndex: "mixture_name" },
  { title: t("Code-Id"), dataIndex: "mixture_code" },
  { title: t("mm Slump for Correction"), dataIndex: "slump_deviation_week" },
];

function ClosePopup(props: { open: boolean; onClose: () => void }) {
  const { t, i18n } = useTranslation();
  return (
    <Modal
      open={props.open}
      footer={null}
      closeIcon={
        <span className="flex items-center justify-center w-full h-full">
          <img
            src="/images/actions/close.svg"
            alt="close"
            onClick={props.onClose}
            className="w-10 h-10"
          />
        </span>
      }
    >
      <div className="flex flex-col gap-10 items-center" dir={i18n.dir()}>
        <div className="flex flex-col gap-5 items-center">
          <div className="text-lg font-semibold">
            The report has not been saved!{" "}
          </div>
          <span className="text-4xl font-bold text-center ">
            Unsaved
            <span className="text-[#E02424]"> changes will be lost </span>
            and cannot be recovered.
          </span>
        </div>
        <span className="flex gap-5">
          <button
            className="bg-main-orange text-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
            onClick={props.onClose}
          >
            Return To Report
          </button>
          <button
            className="text-main-orange border-2 border-main-orange bg-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
            onClick={props.onClose}
          >
            Exit
          </button>
        </span>
      </div>
    </Modal>
  );
}

function SaveAndExitPopup(props: {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  acceptedReports: MixtureBehavior[];
}) {
  const { i18n } = useTranslation();
  return (
    <Modal
      open={props.open}
      footer={null}
      width={700}
      closeIcon={
        <span className="flex items-center justify-center w-full h-full">
          <img
            src="/images/actions/close.svg"
            alt="close"
            onClick={props.onClose}
            className="w-10 h-10"
          />
        </span>
      }
    >
      <div className="flex flex-col gap-10 items-center" dir={i18n.dir()}>
        <div className="flex flex-col gap-5 items-center">
          <div className="text-lg font-semibold">
            The weekly report has been successfully closed!
          </div>
          <span className="text-4xl font-bold text-center ">
            <span className="text-[#0E9F6E]"> Remember to implement </span>
            the following corrections in the batching plant
          </span>
        </div>
        <div className="grid grid-cols-4">
          <span className="text-lg font-semibold col-span-2">Mix Design</span>
          <span className="text-lg font-semibold">Code</span>
          <span className="text-lg font-semibold">mm Slump for Correction</span>
          {props.acceptedReports.map((item, i) => {
            return (
              <>
                <span className="col-span-2">{item.mixture_name}</span>
                <span>{item.mixture_code}</span>
                <span>{item.slump_deviation_week}</span>
                <span className="col-span-3 border-b-2 border-[#E5E5E5] mt-5"></span>
              </>
            );
          })}
        </div>
        <span className="flex gap-5">
          <button
            className="text-main-orange border-2 border-main-orange bg-white py-2.5 px-5 font-bold text-sm rounded-[5px] flex items-center gap-2.5"
            onClick={props.onClose}
          >
            <img
              src="/images/actions/open-link.svg"
              alt="share"
              className="h-4"
            />
            <span>Share</span>
          </button>
          <button
            className="bg-main-orange text-white py-2.5 px-5 font-bold text-sm rounded-[5px]"
            onClick={props.onConfirm}
          >
            Confirm
          </button>
        </span>
      </div>
    </Modal>
  );
}

@inject("authStore")
@observer
class ReportsCreator extends React.PureComponent<Props, any> {
  @observable filters: Record<string, any> = {};

  onFilterChange = async (filterName: string, filterValue: any) => {
    switch (filterName) {
      case "factory_id":
        this.filters.factory_id = filterValue;
        break;
    }
  };

  render() {
    return (
      <ReportsStyled>
        <div className="content-page">
          <TopFilter
            class={"concreate-filter"}
            background={false}
            filters={["date", "factory", "loading_concreate_tree"]}
            changeTrackNumber={(track_number) =>
              this.onFilterChange("sensor_id", track_number)
            }
            onFilterChange={this.onFilterChange}
          />
        </div>
      </ReportsStyled>
    );
  }
}
export default withRouter(ReportsCreator);
