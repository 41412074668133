import styled from "styled-components";

const WaterAddonsStyled = styled.div`
  .sensor_group_1 {
    min-width: 250px;
    background: #fff;
    padding: 10px;
    position: absolute;
    width: 200px;
    right: 0;
    height: 100vh;
  }

  .t2.aa {
    text-align: left;
  }

  .close {
    background: #363331;
    position: absolute;
    top: -8px;
    left: -10px;
    width: 22px;
    text-align: center;
    border-radius: 28px;
    color: #fff;
    z-index: 99;
  }

  .flex.full {
    gap: 13px;
  }
  .sensor_name {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    width: 272px;
    height: 129px;
    text-align: center;
    margin-right: 14px;
    padding: 10px;
    display: flex;
    -webkit-box-pack: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .bold.t1 {
    font-size: 23px;
  }

  .t2 {
    font-size: 15px;
    font-weight: 500;
  }

  td.ant-table-cell {
    font-size: 13px;
    max-width: 20px !important;
    padding: 15px !important;
    text-align: center;
    font-weight: 600;
  }

  .box.adding-box {
    border: none;
    box-shadow: none;
  }

  .box-sum {
    background: #fff;
    padding: 10px;
    width: 20%;
    border-radius: 2px;
  }

  .box-sum .flex {
    width: auto;
  }

  .box-sum .box-sum {
    color: #ff6700;
    font-size: 64px;
    font-weight: bold;
    line-height: 1;
    padding: 0px;
  }

  .box-title.no-desc {
    margin-top: 19px;
    margin-left: 21px;
  }
  .box-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
  }

  .box-desc {
    font-size: 14px;
    font-weight: 600;
  }

  .title-b {
    text-decoration: underline;
  }

  .non_active .sensor_name {
    cursor: not-allowed !important;
  }
  .sensor-wrap {
    position: relative;
  }

  .alert_add {
    text-align: center;
    color: red;
    font-weight: bold;
    gap: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 10vw;
  }

  .addon-add {
    background: #ff6700;
    position: absolute;
    top: 35%;
    right: 0;
    font-size: 33px;
    width: 35px;
    height: 35px;
    text-align: center;
    border-radius: 28px;
    color: #fff;
    z-index: 99;
    cursor: pointer;
    line-height: 0.8;
  }

  .sensor.active .box {
    background-color: #fee1ce !important;
  }

  span.large-difference {
    position: absolute;
    width: 100%;
    background: #e02424;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }

  td.ant-table-cell {
    position: relative;
  }

  th.ant-table-cell {
    text-align: center !important;
  }

  .sensor_status.flex.gap20 {
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;
  }

  .close_bar {
    width: 63px;
    min-width: 63px;
  }
  .sensor_status_item {
    height: 100vh;
    overflow-y: auto;
    padding: 9px;

    .sensor_name {
      background: #3c3b3a53;
      width: 100%;
      text-align: center;
    }

    ul {
      padding: 0px;

      .name {
        font-weight: 500;
      }
    }
  }

  .item.active:after {
    bottom: -3px;
    content: "";
    background: #ff6700;
    width: 154%;
    height: 4px;
    position: absolute;
    left: -6px;
    position: absolute;
  }

  .item.active {
    position: relative;
  }

  .arrow {
    max-width: 39px;
  }
  .flex {
    width: max-content;
  }

  .select-filter .item {
    color: #9ca3af;
  }

  .select-filter .active {
    color: #000;
  }

  .title {
    font-size: 32px;
    line-height: 45px;
  }
  .flex.select-filter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    padding-bottom: 1px;
    border-bottom: 2px solid #c5c3c3;
    font-size: 18px;
    font-weight: 600;
  }

  .sensor_status.scrollbar {
    width: 100%;
    overflow-y: auto;
    padding-bottom: 18px;
  }

  .wrap-page {
    width: 64vw;
  }

  .full_data {
    width: 75vw !important;
    .sensor_status.scrollbar {
      width: 72.3vw;
    }
  }

  .sensor_status_item .sensor {
    border-radius: 10px;
    padding: 9px;

    font-weight: bold;
  }

  .height-scroll {
    flex-direction: column;
    gap: 15px;
    height: 78vh;
    overflow-y: scroll;
  }

  .unloading_mixer {
    position: absolute;
    top: 11px;
    right: 27px;
  }

  .red_1 {
    background: #e02424ab;
    color: #fff;
  }

  .red_2 {
    background: #e02424;
    color: #fff;
  }
  .green_1 {
    background: #b4b746;
    color: #fff;
  }

  .green_2 {
    background: #e02424;
    color: #fff;
  }

  @media screen and (max-width: 600px) {
    .flex.padding20.gap20 {
      flex-direction: column-reverse;
      width: 100%;
    }

    .sensor_name {
      width: 100%;
    }

    .full_data {
      width: 100% !important;
    }

    .box-sum.layout-box {
      min-width: 48%;
    }

    .flex.full {
      padding: 20px 0px;
      flex-wrap: wrap;
    }

    .close_bar {
      display: none;
    }

    .sensor_group_1 {
      width: 100%;
    }

    .box-sum.layout-box {
      min-width: 48%;
    }

    th.ant-table-cell {
      font-size: 13px;
    }

    td.ant-table-cell {
      text-align: center !important;
      min-width: 100px;
      width: 21px !important;
    }
    .sensor_status.scrollbar.flex.gap20 {
      padding: 0px 0px 20px 0px !important;
    }

    .box-title {
      font-size: 30px !important;
    }

    .box-title {
    }

    .box-sum {
      font-size: 43px !important;
    }

    .box-desc {
      line-height: 18px;
    }

    .box-title.no-desc {
      margin: 7px 17px 0px 10px !important;
    }

    .sensor_status {
      width: 100% !important;
      padding: 0px 0px 20px 0px !important;
    }

    .sensor_group_2 .sensor {
      width: 46%;
      margin-right: 1px;
    }

    .sensor-wrap.relative.non_active {
      width: 100% !important;
    }

    .box.marginVer20 {
      width: 100% !important;
      max-width: 100%;
      margin: 0px;
    }

    .sensor_group_1 {
      margin-top: 43px;
    }

    .sensor_status_item {
      overflow: inherit !important;
      width: 100% !important;
      padding: 0px !important;
    }

    .table-data .flex {
      width: auto;
    }
  }
`;

export { WaterAddonsStyled };
